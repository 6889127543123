<template>
    <b-form @keyup.enter="save" @submit.prevent>
        <p class='h2 mb-4'>
            {{$t('pages.selfservice.activateUser.title')}}
        </p>
        <p class='text-center mb-0'>
            <small>{{$t('pages.selfservice.activateUser.userQueryInstruction')}}</small>
        </p>
        <b-form-group>
            <fr-floating-label-input v-model="privateEmailAddress" fieldName="privateEmailAddress" :label="$t('common.placeholders.privateEmailAddress')" type="text" autofocus="true"></fr-floating-label-input>
        </b-form-group>

        <b-form-group>
            <fr-floating-label-input v-model="principalName" fieldName="principalName" :label="$t('common.placeholders.principalName')" type="text" autofocus="true"></fr-floating-label-input>
        </b-form-group>

        <b-button @click="save" size="lg" :block="true" variant="primary">
            {{$t("pages.selfservice.activateUser.advanceStageButtonText")}}
        </b-button>
    </b-form>
</template>

<script>
import FloatingLabelInput from '@/components/utils/FloatingLabelInput';

/**
 * @description Selfservice stage for multiple selfservice flows, typically used with an email to locate a user in the system to continue on with further
 * selfservice stages
 *
 **/
export default {
    name: 'Validate-One-Time-Password',
    components: {
        'fr-floating-label-input': FloatingLabelInput
    },
    props: {},
    data () {
        return {
            oneTimePassword: ''
        };
    },
    methods: {
        getData () {
            return {
                oneTimePassword: this.oneTimePassword
            };
        },
        save () {
            /* istanbul ignore next */
            this.$emit('advanceStage', this.getData());
        }
    }
};
</script>
